
import themes from '../../../../themes';

export const styles = {
    content: {
        paddingHorizontal: 25,
        zIndex: -1
    },
    contentMenu: {
        flex: 1,
        borderWidth: 1,
        backgroundColor: 'black'
    },

    titleCategorie: { 
        textAlign: 'center', 
        fontSize: 40, 
        color: 'white' ,
        fontWeight: 'bold',
    },
    item: {
        borderRadius: 8,
        border: '1px solid #D9D9D9', 
        textDecoration: "none",
        backgroundColor: '#fff',
        padding: 20,
         textAlign: "center"
    },

    itemDrink: {
        marginVertical: 8,
        marginHorizontal: 5,
        flexDirection: 'row',
        width: '32%',
        height: 157,
        borderWidth: 2,
        borderRadius: 8,
        borderColor: "#D9D9D9"
    },


    contentItemImage: {
        width: '100%',
    },

    contentItemImageDrink: {
        width:  110,
        height: 157,
    },

    price: {
       
    },

    priceDrink: {
        position: 'absolute',
        bottom: 20,
        left: 15
    },
    contentItemDescription: {
        paddingLeft: 15,
        paddingRight: 13,
        width: '90%',
    },

    contentItemDescriptionDrink: {
        paddingLeft: 20,
        width: '54%',
    },

    contentTitle: {
        flexDirection: 'row',
    },  
    imageMenu: {
        width: '100%',
        resizeMode: 'cover',
        borderRadius: 8,
    },

    imageMenuDrink: {
        width:  110,
        height: 153,
        resizeMode: 'cover',
        borderRadius: '8px 8px 0 0',
        padding: 10,
    },

    contentTags: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 32,
        width: '100%',

    },
    imageTag: {
        width: 30,
        height: 30,
        resizeMode: 'cover',
    },
    modalQr: {
        flex: 1,
        zIndex: 999,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
    },

    contentPrice: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 111,
        height: 50,
        borderRadius: 50,
        display: 'flex',
        border: '1px solid #000',
        justifyContent: "center",
        alignSelf: 'center',

        margin: '0 auto',
    },


    backButtonContent: {
       
      
        position: 'fixed',
        top: '25%',
        right: 20,
        textAlign: 'center',
    },
    backButton: {
        width: 50,
        height: 50,
          borderRadius: 100,
        textAlign: 'center',
        paddingVertical: 15,
        backgroundColor: themes.colors.primary,
        fontSize: '16px', 
        color: '#ffffff', 
       
        cursor: 'pointer', 
        border: 'none', 
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    },
    backButtonRed: {
        borderRadius: 5,
        textAlign: 'center',
        padding: 10,
        paddingVertical: 15,
        backgroundColor: themes.colors.primary,
    },
    textBack: {
        color:  themes.colors.white,
        alignSelf: 'center',
        fontSize: 22,
        textTransform: 'uppercase',
        margin: 6
    },
    textBackRed: {
        color:  themes.colors.white,
        alignSelf: 'center',
        fontSize: 20
    },


    contentRecommendedMobile: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '16px',
        paddingHorizontal: 20
    },

    contentRecommendedDesktop: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '16px',
        paddingHorizontal: 20
    },

    contentRecommendedTablet: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '16px',
        paddingHorizontal: 20
    },


    description: {fontSize: 14,textAlign: 'left', WebkitLineClamp: 2,  display: '-webkit-box', WebkitBoxOrient: 'vertical',overflow: 'hidden',}
}
