import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import translate from '../../../../helpers/translate';
import translateMenu from '../../../../helpers/translateMenu';
import {useSelector} from 'react-redux';
import Margin from '../../../../components/shared/margin';
import {constants} from '../../../../config/constants';
import {styles} from './styles';
import LayoutContent from '../../../../components/layouts/content2';
import {commonStyles as stylesCommon} from '../../../../styles/common';
import {Tracking} from '../../../../hooks/useTracking';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './style.css'
function Index() {
    const data = useLocation().state;
    const navigate = useNavigate();
    const carouselRef = useRef(null);

    const isDrink = false;
    const {menu, allDish} = data;


    const {
            allmenu
        } = useSelector((state) => state.initLoad);

    const [isFocused, setIsFocused] = useState(true);
    const [indexCarrousel, setIndexCarrousel] = useState(0);
    const [currentItem, setCurrentItem] = useState(menu);
    const modeDark = useSelector((state) => state.modeDark).modeDark;
    const commonStyles  = stylesCommon(modeDark); 
    const {t, i18n} = useTranslation();
    const langEng = i18n.language === 'en' ?? false;

    const [startTime, setStartTime] = useState(null);

    const stylesImage = isDrink ? styles.imageDrink : styles.image;

    const [dataDesserts, setDataDesserts] = useState(false);
    const [dataSpecial, setDataSpecial] = useState(false);


      useEffect(() => {
        allmenu.data.map((item) => {
            if (item.id === 43) {
                setDataDesserts(item);
            }

             if (item.id === 61) {
                setDataSpecial(item);
            }
        });
     }, [allmenu]);

    
    
    const [hash, setHash] = useState('');

    useEffect(() => {
      const hash = localStorage.getItem('hash');
      setHash(hash);
    }, []);


    useEffect(() => {

        const handleFocus = () => {
            setIsFocused(true);
        };
      
        const handleBlur = () => {
            setIsFocused(false);
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);
    
        return () => {
          window.removeEventListener('focus', handleFocus);
          window.removeEventListener('blur', handleBlur);
        };
    }, []);



    useEffect(()=>{
        setCurrentItem(allDish[indexCarrousel]);
    }, [indexCarrousel]);


    useEffect(()=>{
        const data = {
            menu_id: menu.id,
            type: 'clicks',
        }
        Tracking(data);
    }, []);


    useEffect(() => {
        if (isFocused) {
            positionDish();
            setStartTime(Date.now());
        }else{
            if (startTime !== null) {
                const endTime = Date.now();
                const elapsedTime = endTime - startTime;
                
                const data = {
                    menu_id: menu.id,
                    type: 'timing',
                    timing: elapsedTime / 1000,
                };
                
                //Tracking(data);
            }
            setStartTime(null);
        }

        return () => {
            console.log('clean screen');
        };
    }, [isFocused]);

    const positionDish = async () =>{
        const indexDish = await allDish.findIndex(item => item.id === menu.id);
        
        setTimeout(()=>{
            carouselRef.current.isAnimationAllowed = false;
            carouselRef.current.goToSlide(indexDish + 2);
        }, 100)
    }



    const next = () =>{
        carouselRef.current.next();
    }

    const prev = () =>{
        carouselRef.current.previous();
    }

    
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };


      const CustomArrow = ({ onClick, ...rest }) => {
        const {
          onMove,
          carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <></>;
      };


      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      

      useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);



    let device;
   
    if (windowWidth >= 1024) {
        device = 'desktop';
    } else if (windowWidth >= 768) {
        device =  'tablet';
    } else {
        device = 'mobile'; 
    }



    function NewlineText(props) {
        const text = props.text;
        const newText = text.split('\n').map(str => <p>{str}</p>);
        
        return newText;
      }

    const ContentImage = ({item}) => {
        const [imageError, setImageError] = useState(false);

        const handleImageError = () => {
            setImageError(true);
        };
    
        
        return <>
                {!isDrink && 
                    <div style={styles.contentIconTags}>
                        
                    </div>
                }

                <div  style={
                    isDrink ? (
                        {
                            flexDirection: 'row-reverse',
                        }
                    ): (
                        {}
                    )
                }>
                    <div style={
                        isDrink ? (
                            {
                                width: '50%'
                            }
                        ): (
                            {}
                        )
                    }>
                        

                        {imageError ? (
                            <img 
                                style={stylesImage}
                                src={
                                    device === 'mobile' ? 
                                    require('../../../../assets/images/placeholder.jpg')
                                    : 
                                    require('../../../../assets/images/placeholer_horizontal.jpg')
                                }alt="Placeholder" />
                        ) : (
                            <img
                                style={stylesImage}
                                onError={handleImageError}
                                src={
                                    device === 'mobile' ? 
                                        `${constants.pathImagesMenu}/${item.image}` 
                                        : 
                                        `${constants.pathImagesMenu}/${item.imagehorizontal}`
                                }
                            />
                        )}


                        
                        
                    </div>
                    
                    <div style={
                        isDrink ? (
                            {
                                width: '50%'
                            }
                        ): (
                            {}
                        )
                    }>
                        <Margin top={10} />
                        <div style={styles.contenTitle}>
                            <div style={styles.contentTags}>
                                <p style={{...commonStyles.txt_h1_bold, ...{fontSize: 32, textAlign: 'left', margin: 0, color: '#000',}}}>
                                    {langEng ? item.name : translate(i18n.language, item.language, item.name)}
                                </p>
                            </div>

                            <div style={styles.contentPrice}>
                                <p style={{...commonStyles.txt_h1_bold, ...{fontSize: 30, textAlign: 'left', margin: 0, color: '#fff'}}}>
                                    $ {item.price.toFixed(2)}
                                </p>
                            </div>

                        </div>
                        
                    
                        <div style={{ ...styles.contentDescription,
                            ...{
                                flexDirection: 'column',
                            }}}>
                            <div style={
                                isDrink ? (
                                    {
                                        width: '90%'
                                    }
                                ): (
                                    {
                                        width: '100%'
                                    }
                                )
                            }>
                                <p style={{
                                        ...commonStyles.txt_p_regular_light, 
                                        ...styles.pDescription,
                                        ...{textAlign: 'center', fontSize: 14, color: '#000'}
                                }}>
                                    {langEng ? 
                                        <NewlineText text={item.regular_decription.replace(/\\n/g,'\n')}/>
                                    : 
                                        <NewlineText text={
                                            translateMenu(
                                                i18n.language, 
                                                item.language, 
                                                item.regular_decription, 
                                                'regular_decription'
                                            )
                                        }/>
                                    }
                                </p>

                            </div>
                            <div style={
                                isDrink ? (
                                    {
                                        width: '90%'
                                    }
                                ): (
                                    {
                                        width: '100%'
                                    }
                                )
                            }>

                            {/*  <div>
                                    <ReactPlayer url={require('../../../../assets/banner/sabores_redes.mp4')}
                                                playing={true}
                                                loop={true}
                                                muted={true}
                                                width="100%"
                                                height="100%"
                                                style={styles.backgroundVideo} 
                                    />
                                </div>*/}

                                
                                
                            </div>
                    </div>


                    <br/>
                    <br/>
                    
                       
                    </div>
                </div>
            
            </>
    }


      
    return (
        <LayoutContent>
            <div className='content-detail-dish' style={{ ...styles.scrollview }}>

                 <div style={styles.backButtonContent}>
                   
                   
                </div>

                  

               

                <div style={{ position: 'relative' }}>
                      <button className='btn-nav' style={{...styles.backButton, ...{border: '2px solid white',position: 'absolute', top: '35%',left: -20, zIndex: 999,backgroundColor: '#DA2121', width: 40, height: 40, borderRadius: 100,display: 'flex', justifyContent: 'center', alignItems: 'center'}}} 
                        onClick={() => prev()}
                    >
                        <img src={ require('./assets/prev.png')} style={{width: 25}} />
                    </button>

                    <Carousel 
                    ref={carouselRef}
                    responsive={responsive}
                    swipeable={true}
                    showDots={false}
                    infinite={true}
                    customRightArrow={<CustomArrow />}
                    customLeftArrow={<CustomArrow />}
                >
                    {allDish.map((item, key)=>{
                        return <>
                            
                             <ContentImage item={item} />
                        </>
                        
                    })}
                    
                
                    </Carousel>
                    
                    <button className='btn-nav' style={{...styles.backButton, ...{border: '2px solid white',position: 'absolute',  top: '35%',right: -20, zIndex: 999,backgroundColor: '#DA2121', width: 40, height: 40, borderRadius: 100,display: 'flex', justifyContent: 'center', alignItems: 'center'}}} 
                        onClick={() => next()}
                    >
                         <img src={ require('./assets/next.png')} style={{width: 25}} />
                    </button>

                </div>

                 <div className='options-detail' style={{display: 'flex', justifyContent: "space-between"}}>
                             <Link
               
                                    to={`/MenuList/${hash}/${dataDesserts.id}`}
                                    state={{ dataDesserts, allDish }}className='item' style={{width: '47%', backgroundColor: '#DA2121', borderRadius: 16, textDecoration: 'none'}}>
                                <img style={{ width: '100%', borderRadius: 16 }} src={require('./assets/66d3403e86429.png')} />
                                <p style={{color: 'white', fontSize: 25, fontWeight: 'bold', margin: 10,}}>DESSERTS</p>
                            </Link>
                           <Link
               
                                    to={`/MenuList/${hash}/${dataSpecial.id}`}
                                    state={{ dataSpecial, allDish }}className='item' style={{width: '47%', backgroundColor: '#DA2121', borderRadius: 16, textDecoration: 'none'}}>
                                <img style={{ width: '100%', borderRadius: 16 }} src={require('./assets/66d3425b34969.png')} />
                                <p style={{color: 'white', fontSize: 25, fontWeight: 'bold', margin: 10}}>SPECIALS</p>
                            </Link>
                        </div>

                       <br/>
                <br />
                

                
                  
                { /** 
                 * <div style={{textAlign: 'center', width: '100%'}}>
                        <p style={{...commonStyles.txt_h1_bold, ...{fontSize: 32, textAlign: 'center', margin: 0, color: '#000'}}}>
                                    Recommended weekend
                        </p>
                          <br/>
                        

                       <div className='recomended-weekend' style={{display: 'flex', justifyContent: "space-between"}}>
                            <div  className='item-categorie' style={{width: '25%', backgroundColor: '#fff', borderRadius: 16, padding: 20}}>
                                <img style={{ width: '100%' }} src={require('./assets/bandejapaisa.png')} />
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Bandeja Paisa</p>
                                
                                <div style={{ flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: 111,
                                        height: 40,
                                        borderRadius: 50,
                                        display: 'flex',
                                        border: '1px solid #000',
                                        justifyContent: "center",
                                        alignSelf: 'center',

                                        margin: '0 auto',}}>
                                    <div style={styles.price}>
                                        <p style={{...commonStyles.txt_p_regular_light, ...{textAlign: 'center', color: '#000'}}}>${menu.price.toFixed(2)}</p>
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div  className='item-categorie' style={{width: '25%', backgroundColor: '#fff', borderRadius: 16, padding: 20}}>
                                <img style={{ width: '100%' }} src={require('./assets/casuela.png')} />
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Bandeja Paisa</p>
                              

                                 <div style={{ flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: 111,
                                        height: 40,
                                        borderRadius: 50,
                                        display: 'flex',
                                        border: '1px solid #000',
                                        justifyContent: "center",
                                        alignSelf: 'center',

                                        margin: '0 auto',}}>
                                    <div style={styles.price}>
                                        <p style={{...commonStyles.txt_p_regular_light, ...{textAlign: 'center', color: '#000'}}}>${menu.price.toFixed(2)}</p>
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>

                            </div>
                            <div  className='item-categorie' style={{width: '25%', backgroundColor: '#fff', borderRadius: 16, padding: 20}}>
                                <img style={{ width: '100%' }} src={require('./assets/casuela.png')} />
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Bandeja Paisa</p>
                                

                                 <div style={{ flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: 111,
                                        height: 40,
                                        borderRadius: 50,
                                        display: 'flex',
                                        border: '1px solid #000',
                                        justifyContent: "center",
                                        alignSelf: 'center',

                                        margin: '0 auto',}}>
                                    <div style={styles.price}>
                                        <p style={{...commonStyles.txt_p_regular_light, ...{textAlign: 'center', color: '#000'}}}>${menu.price.toFixed(2)}</p>
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                */}
                    

               
                <Margin bottom={100} />



                
            </div>
        </LayoutContent>
    );
}

export default Index;